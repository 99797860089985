import PageContainer from '@components/LayoutStructure/PageContainer';
import { ControllerAdapter } from '@controllers/controller.adapter';
import { GetServerSideProps } from '@services/magnolia/api';
import type { GetServerSidePropsContext } from 'next';
import { findSiteForContext, SiteDefinition } from '~/contexts/SiteContext';
import { PageProps, SSRPageProps } from '~/types/Magnolia';

const getPageData = async (
  context: GetServerSidePropsContext
): Promise<PageProps> => {
  const catchall = context.params?.catchall;

  context.resolvedUrl =
    typeof catchall === 'string'
      ? catchall
      : Array.isArray(catchall)
      ? `/${[...new Set(catchall)].join('/')}`
      : `/home`;

  const site = findSiteForContext(context) as SiteDefinition;
  const ca = new ControllerAdapter(site);
  return await ca.controller.getPageData(context);
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  return GetServerSideProps(context, getPageData(context));
};

export default function Page({ pageData }: SSRPageProps) {
  const data = pageData as PageProps;

  if (data) {
    return (
      <>
        <PageContainer
          page={data.page}
          templateAnnotations={data.templateAnnotations}
          context={data.context}
        />
      </>
    );
  }
}
